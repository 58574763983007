import React, { Component } from 'react'
import { Link } from 'gatsby'
import ArrowUp from 'react-ionicons/lib/ArrowUp'

const Services = ({ diensten, arrowColor }) => {
  return (
    <section id="services" className="services section">
      <div className="container">
        <h2 className="between-title has-text-centered">Diensten</h2>
        <div className="intro has-text-centered pb-6">
          We bieden een hele waaier aan internet en offline diensten, vaak ook
          in samenwerking met ons netwerk aan specialisten.
        </div>
        <div className="columns">
          {diensten
            ? diensten.map(({ node: dienst }) => (
                <div key={dienst.id} className="column is-flex is-one-third">
                  <div className="inner-wrapper relative bb shadow p-5">
                    <h3 className="inner-title pt-3">
                      <Link to={`/dienst/${dienst.slug}/`}>{dienst.title}</Link>
                    </h3>
                    <div className="service-content">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: dienst.extra.inleiding,
                        }}
                      />
                    </div>
                    <Link to={`/dienst/${dienst.slug}/`} className="more-arrow">
                      <ArrowUp color={arrowColor} height="30px" width="30px" />
                    </Link>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </section>
  )
}

export default Services
