import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import Werk from '../components/Werk'
import Services from '../components/Services'
import Academie from '../components/Academie'

require('typeface-open-sans')

export default class IndexPage extends React.Component {
  render() {
    const { data, path } = this.props
    const { edges: werk } = data.werk
    const { edges: diensten } = data.diensten
    const { edges: posts } = data.allWpPost
    const { title, metaDesc } = data.site.siteMetadata

    return (
      <Layout path={path}>
        <Helmet>
          <title>{`Webdesign - WordPress - SEO - Grafisch Ontwerp | ${title}`}</title>
          <meta name="description" content={metaDesc} />
          <meta name="og:description" content={metaDesc} />
        </Helmet>
        <Intro />
        <Werk werk={werk} arrowColor="#FFF" />
        <Services diensten={diensten} arrowColor="#888" />
        <Academie posts={posts} arrowColor="#888" />
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allWpPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        metaDesc
      }
    }
    werk: allWpWerk(limit: 4) {
      edges {
        node {
          id
          title
          content
          slug
          extra {
            inleiding
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: BLURRED
                    width: 672
                  )
                }
              }
            }
          }
        }
      }
    }
    diensten: allWpDienst(sort: { fields: databaseId, order: DESC }, limit: 3) {
      edges {
        node {
          id
          title
          content
          slug
          extra {
            inleiding
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
    allWpPost(sort: { fields: date, order: DESC }, limit: 2) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`
