import React from 'react'
import { Link } from 'gatsby'
import parse from 'html-react-parser'
import SeoCanon from './SeoCanon'
import ArrowUp from 'react-ionicons/lib/ArrowUp'

const Academie = ({ posts, arrowColor }) => {
  return (
    <section id="academie" className="academie section colored-back">
      <div className="container">
        <h2 className="between-title has-text-centered">Academie</h2>
        <div className="intro has-text-centered pb-6">
          Op zoek naar meer basiskennis over het web?
          <br /> Check onze <Link to="/academie/">Academie</Link>{' '}
          kennisdatabank.
        </div>

        <div className="columns is-multiline">
          {posts.map(({ node: post }) => (
            <div className="column is-6 is-flex" key={post.id}>
              <div className="inner-wrapper relative is-flex bb shadow p-5">
                <h3 className="inner-title pt-3">
                  <Link to={`/academie/${post.slug}/`}>
                    {parse(post.title)}
                  </Link>
                </h3>
                <div
                  className="inner-content mb-4"
                  dangerouslySetInnerHTML={{
                    __html: post.excerpt,
                  }}
                />
                <Link to={`/academie/${post.slug}/`} className="more-arrow">
                  <ArrowUp color={arrowColor} height="30px" width="30px" />
                </Link>
              </div>
            </div>
          ))}
        </div>
        <SeoCanon />
      </div>
    </section>
  )
}

export default Academie
