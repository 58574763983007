import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

const SeoCanon = () => {
  return (
    <StaticQuery
      query={graphql`
        query TagQuery {
          tags: allWpTag {
            edges {
              node {
                name
                slug
              }
            }
          }
        }
      `}
      render={(data) => (
        <div className="seo-canon columns is-centered">
          <div className="column is-three-quarters has-text-centered">
            <h3 className="between-title mt-6 mb-4">
              Trefwoorden voor Academie artikelen
            </h3>
            <div>
              {data.tags
                ? data.tags.edges.map((tag) => (
                    <Link
                      className="button is-medium m-2"
                      to={`/trefwoord/${tag.node.slug}/`}
                    >
                      {tag.node.name}
                    </Link>
                  ))
                : null}
            </div>
          </div>
        </div>
      )}
    />
  )
}

export default SeoCanon
