import React from 'react'
import { Link } from 'gatsby'

const Intro = () => {
  return (
    <section className="intro section">
      <div className="container">
        <h1 className="is-uppercase mb-6">WEB - SEO - GRAFISCH ONTWERP</h1>
        <div className="intro">
          Tijd voor een (nieuwe) website? Die bouwen we met WordPress. We
          gebruiken WordPress daarnaast ook als headless CMS. Gedrukte media of
          logo? Ook daarvoor bent u aan het juiste adres. Neem vrijblijvend{' '}
          <Link to="/contact" className="reveal">
            <span data-content="contact" aria-hidden="true"></span>
            contact
          </Link>{' '}
          op.
        </div>
        <div className="mt-6">
          Benieuwd naar de prijs van een basis website? Bekijk ons{' '}
          <Link to="/prijzen" className="reveal">
            <span data-content="prijsoverzicht" aria-hidden="true"></span>
            prijsoverzicht
          </Link>
          {'!'}
        </div>
      </div>
    </section>
  )
}

export default Intro
