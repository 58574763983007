import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import ArrowUp from 'react-ionicons/lib/ArrowUp'

const Werk = ({ werk, arrowColor }) => {
  return (
    <section className="works section colored-back">
      <div className="container">
        {werk
          ? werk.map(({ node: singlework }, index) => (
              <div
                key={singlework.id}
                className={`columns mb-6 is-gapless ${
                  index % 2 != 0 ? 'row-reverse' : ''
                }`}
              >
                <div className="column is-6">
                  <Link to={`/werk/${singlework.slug}/`}>
                    {singlework.featuredImage && (
                      <GatsbyImage
                        image={
                          singlework.featuredImage?.node?.localFile
                            ?.childImageSharp?.gatsbyImageData
                        }
                        className="shadow"
                        alt={singlework.featuredImage.node.altText}
                      />
                    )}
                  </Link>
                </div>
                <div className="column is-6 is-flex vcenter flex-col">
                  <div
                    className={`work-desc-wrap work-desc-wrap-height ${
                      index % 2 == 0 ? 'right' : 'left'
                    } transparent-back is-flex vcenter flex-col`}
                  >
                    <div className="work-desc">
                      <h3 className="inner-title">
                        <Link to={`/werk/${singlework.slug}/`}>
                          {singlework.title}
                        </Link>
                      </h3>
                      <div
                        className="work-content"
                        dangerouslySetInnerHTML={{
                          __html: singlework.extra.inleiding,
                        }}
                      />
                      <Link
                        to={`/werk/${singlework.slug}/`}
                        className="more-arrow"
                      >
                        <ArrowUp
                          color={arrowColor}
                          height="30px"
                          width="30px"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : null}
        <div className="meer-link has-text-centered">
          <Link to="/werk/">MEER WERK</Link>
        </div>
      </div>
    </section>
  )
}

export default Werk
